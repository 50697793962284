import React, {useEffect} from "react";
import './InfoSection.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import icon1 from '../../../assets/icon1.webp'
import icon2 from '../../../assets/icon2.webp'
import icon3 from '../../../assets/icon3.webp'
import icon4 from '../../../assets/icon4.webp'
import { SpeedInsights } from "@vercel/speed-insights/react";

const InfoSection = ({onAssetsLoaded}) => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

        useEffect(() => {
            const LoadAssets = async () => {
                try {
                    const icon1Promise = new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = icon1;
                        img.onload = () => resolve();
                        img.onerror = () => reject();
                    });
                    const icon2Promise = new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = icon2;
                        img.onload = () => resolve();
                        img.onerror = () => reject();
                    });
                    const icon3Promise = new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = icon3;
                        img.onload = () => resolve();
                        img.onerror = () => reject();
                    });
                    const icon4Promise = new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = icon4;
                        img.onload = () => resolve();
                        img.onerror = () => reject();
                    });
                    await Promise.all([icon1Promise, icon2Promise, icon3Promise, icon4Promise]);
                    onAssetsLoaded();
                }
                catch (error) {
                    console.error("Error loading assets:", error);
                }
            }
            LoadAssets();
        }
        , []);
        
    return (
        <div>
            <SpeedInsights />
            <section className="info-section">
                <div className="info-content">
                <div className="info-item" data-aos="fade-up">
                    <img src={icon1} alt="Collaborative Growth Icon" />
                    <div>
                    <h3>Collaborative Growth</h3>
                    <p>Partner with us to co-create innovative solutions that drive mutual success and industry leadership.</p>
                    </div>
                </div>
                <div className="info-item" data-aos="fade-up">
                    <img src={icon2} alt="Shared Expertise Icon" />
                    <div>
                    <h3>Shared Expertise</h3>
                    <p>Leverage our technical expertise and insights to enhance your offerings and deliver cutting-edge software to your clients.</p>
                    </div>
                </div>
                <div className="info-item" data-aos="fade-up">
                    <img src={icon3} alt="Joint Innovation Icon" />
                    <div>
                    <h3>Joint Innovation</h3>
                    <p>Work together on groundbreaking projects that push the boundaries of software innovation and elevate your brand.</p>
                    </div>
                </div>
                <div className="info-item" data-aos="fade-up">
                    <img src={icon4} alt="Expanded Opportunities Icon" />
                    <div>
                    <h3>Expanded Opportunities</h3>
                    <p>Unlock new business opportunities and access a broader market through our trusted partnership network.</p>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}

export default InfoSection