import React , {useState , useEffect} from 'react';
import './TregoApp.css';
import Header from './Components/Header/Header';
import Ultimate from './Components/Ultimate/Ultimate';
import ImageSlider from './Components/mobilesSlide/ImageSlider'
import Transportation from './Components/Transportations/Transportation';
import AccommodationComponent from './Components/AccommodationComponent/AccommodationComponent';
import FAQComponent from './Components/Questions/FAQComponent';
import { Helmet } from "react-helmet-async";

import { SpeedInsights } from "@vercel/speed-insights/react";
import LogoAnimation from '../Landing/LogoAnimation';
const TregoApp = () => {

  const [showAnimation, setShowAnimation] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const [isHeaderAssetsLoading , setIsHeaderAssetsLoading ] = useState(true)
  const [isUltimateAssetsLoading , setIsUltimateAssetsLoading ] = useState(true)
  const [isImageSliderAssetsLoading , setIsImageSliderAssetsLoading ] = useState(true)
  const [isTransportationAssetsLoading , setIsTransportationAssetsLoading ] = useState(true)
  const [isAccommodationComponentAssetsLoading , setIsAccommodationComponentAssetsLoading ] = useState(true)
  const [isFAQComponentAssetsLoading , setIsFAQComponentAssetsLoading ] = useState(true)

  useEffect(() => {
    if (!isHeaderAssetsLoading && !isUltimateAssetsLoading && !isImageSliderAssetsLoading && !isTransportationAssetsLoading && !isAccommodationComponentAssetsLoading && !isFAQComponentAssetsLoading) {
      setIsVisible(false);
      setShowAnimation(false);
    }
  }
  , [isHeaderAssetsLoading, isUltimateAssetsLoading, isImageSliderAssetsLoading, isTransportationAssetsLoading, isAccommodationComponentAssetsLoading, isFAQComponentAssetsLoading]);

  const renderContent = () => (
    <div className="home-container">
      <div className="content">
        <Header onAssetsLoaded={() => setIsHeaderAssetsLoading(false)}/>
        <Ultimate onAssetsLoaded={() => setIsUltimateAssetsLoading(false)}/>
        <ImageSlider onAssetsLoaded={() => setIsImageSliderAssetsLoading(false)}/>
        <Transportation onAssetsLoaded={() => setIsTransportationAssetsLoading(false)}/>
        <AccommodationComponent onAssetsLoaded={() => setIsAccommodationComponentAssetsLoading(false)}/>
        <FAQComponent onAssetsLoaded={() => setIsFAQComponentAssetsLoading(false)}/>
      </div>
    </div>
  );



  return (
    <div className="trego-container">
      <SpeedInsights />
      <Helmet>
        <title >Trego App</title>
        <meta name="description" content="Trego App is a mobile application that provides transportation and accommodation services to users. We deliver real-time options, empowering users to make smarter travel decisions, with a focus on efficiency, convenience, and expanding partnerships" />
        <meta name="keywords" content="Trego App, transportation, accommodation, mobile application" />
        <meta property="og:title" content="Trego App" />
        <meta property="og:description" content="Trego App is a mobile application that provides transportation and accommodation services to users. We deliver real-time options, empowering users to make smarter travel decisions, with a focus on efficiency, convenience, and expanding partnerships" />
        <meta property="og:url" content="https://www.tregotech.com/app" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      {showAnimation && (
        <LogoAnimation isVisible={isVisible} onAnimationComplete={() => setShowAnimation(false)} />
      )}
      { renderContent() }
    </div>
  );
};

export default TregoApp;
