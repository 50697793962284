import React, { useEffect, useState } from 'react';
import './FAQComponent.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import rectangleImg from '../../../../assets/JoinWaiting.webp';
import ApiCaller from '../../../../api/handlers';
import SuccessPopup from '../../../../components/SuccessPopup';
import FailurePopup from '../../../../components/FailurePopup';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { use } from 'react';

const FAQComponent = ({ onAssetsLoaded }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const handleSubscribe = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailRegex.test(email)) {
      setShowFailure(true); // Show failure popup on error
      return;
    }
  
    ApiCaller.subscribe(
      email,
      () => {
        setShowSuccess(true); // Show success popup on successful subscription
      },
      () => {
        setShowFailure(true); // Show failure popup on error
      }
    );
  };
  

  const faqs = [
    {
      question: "What is the Trego app?",
      answer: "The Trego app is a mobile application designed to simplify travel booking by allowing users to compare and book trains, buses, and flights from multiple transport providers. It aims to provide a seamless travel experience, helping users find the best options based on price, timing, and convenience."
    },
    {
      question: "How do I download the Trego app?",
      answer: "The Trego app will be available for download on both iOS and Android platforms. You can find it in the App Store and Google Play Store once it's officially launched in 2024. Stay tuned for updates on our website and social media channels!"
    },
    {
      question: "Can I use Trego for international travel bookings?",
      answer: "Currently, Trego is focused on the Egyptian market with plans to expand internationally in the near future. For now, you can use the app to book domestic travel."
    },
    {
      question: "How does the booking process work in the Trego app?",
      answer: "Booking through the Trego app is simple and user-friendly. Users can search for travel options by entering their departure and arrival locations, selecting dates, and comparing available options. Once you find the desired travel option, you can proceed to book directly within the app by entering payment details."
    },
    {
      question: "How do you ensure the security of user data?",
      answer: "At Trego Tech, the security of our users is a top priority. We implement industry-standard security measures, including encryption, to protect personal and payment information. Additionally, we regularly update our systems to guard against potential vulnerabilities and ensure a secure user experience."
    },
    {
      question: "Can I contribute feedback or suggest features for the Trego app?",
      answer: "Absolutely! We value feedback from our users as it helps us improve. You can suggest new features or improvements directly through the app or by contacting our support team at support@tregotech.com. We're committed to building a platform that meets the needs of our users."
    },
    {
      question: "Is there a customer support feature in the Trego app?",
      answer: "Yes, the Trego app includes a customer support feature to assist users with any questions or issues they may encounter. You can access support directly through the app, and our team is committed to providing timely assistance."
    },
    {
      question: "Will there be any loyalty programs or discounts available?",
      answer: "We plan to introduce loyalty programs and promotional discounts to reward our users. More details will be available upon the app's launch, so keep an eye out for announcements on our website and social media."
    }
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const LoadAssets = async () => {
      try {
        const faqComponentPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = rectangleImg;
          img.onload = () => resolve();
          img.onerror = () => reject();
        });
        await Promise.all([faqComponentPromise]);
        onAssetsLoaded();
      }
      catch (error) {
        console.error("Error loading assets:", error);
      }
    }
    LoadAssets();
  }
  , []);

  return (
    <div className="Faqs-section">
      <SpeedInsights />
      <div className="faq-container1">
        <div className="faq-content1">
          <h2 className="faq-title1">Why travel with us?</h2>
          {faqs.map((faq, index) => (
            <div key={index} className={`faq-item1 ${activeIndex === index ? 'open' : ''}`}>
              <div className="faq-question1" onClick={() => handleToggle(index)}>
                <span className={`arrow ${activeIndex === index ? 'open' : ''}`}>
                  <i className="fas fa-chevron-right"></i>
                </span>
                <span>{faq.question}</span>
              </div>
              <div className={`faq-answer1 ${activeIndex === index ? 'open' : ''}`}>
                <div className="faq-answer-content1">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="trego-app-footer">
        <img src={rectangleImg} alt="Footer Image" />
        <div className="trego-app-footer-content">
          <h3>BE THE FIRST TO KNOW</h3>
          <h3>JOIN OUR WAITING LIST</h3>
          <div className="subscribe-form">
            <input
              type="email"
              placeholder="Enter your email"
              required
              className="input-field"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="button" className="trego-app-footer-subscribe-button" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>
      </div>
      {/* Render the success or failure popups */}
      {showSuccess && <SuccessPopup message="Subscribed successfully!" onClose={() => setShowSuccess(false)} />}
      {showFailure && <FailurePopup message="Please enter a valid email and try again." onClose={() => setShowFailure(false)} />}
    </div>
  );
};

export default FAQComponent;
