import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import './HeroSection.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import heroImage from '../../../assets/becomeAPartnerBackground.webp';
import { SpeedInsights } from "@vercel/speed-insights/react";

const HeroSection = ({onAssetsLoaded}) => {
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  // Handle button click to navigate
  const handleContactClick = () => {
    navigate('/contact');
  };

  useEffect(() => {
    const LoadAssets = async () => {
      try {
        const heroImagePromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = heroImage;
          img.onload = () => resolve();
          img.onerror = () => reject();
        });
        await Promise.all([heroImagePromise]);
        onAssetsLoaded();
      }
      catch (error) {
        console.error("Error loading assets:", error);
      }
    }
    LoadAssets();
  }
  , []);
        


  return (
    <div className="partner-page">
      <SpeedInsights />
      <section className="hero1" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-content1" data-aos="fade-up">
          <h1>The Power of Two:</h1>
          <h1>Partner with Purpose!</h1>
        </div>
      </section>

      <section className="blue-section">
        <div className="blue-section-content" data-aos="fade-up">
          <p>
            SUPERCHARGE YOUR BUSINESS BY FILLING GAPS WITH OUR CUTTING-EDGE TECH SOLUTIONS, STARTING <span>TODAY.</span>
          </p>
        </div>
        <button className="cta-button1" data-aos="fade-up" onClick={handleContactClick}>
          Get in touch
        </button>
      </section>
    </div>
  );
};

export default HeroSection;
