import React, { useState , useEffect } from 'react';
import './ContactUs.css';
import SuccessPopup from '../../../components/SuccessPopup';
import FailurePopup from '../../../components/FailurePopup';
import ApiCaller from '../../../api/handlers';
import { SpeedInsights } from "@vercel/speed-insights/react";

const ContactUs = ({onAssetsLoaded}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    description: '',
    reason: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ApiCaller.sendContact(
        formData.email,
        formData.firstName,
        formData.lastName,
        formData.description,
        formData.reason,
        () => {
          setShowSuccess(true);
        },
        () => {
          setShowFailure(true);
        }
      );
    }
    catch (error) {
      console.error('Error submitting form:', error);
      setShowFailure(true);
    }

  };

  useEffect(() => {
    onAssetsLoaded();
  }
  , []);

  return (
    <div className="contact-us-section">
      <SpeedInsights />
      <div className="contact-us-container">
        <h2>Contact us</h2>
        <p>The harder you work for something, the greater you’ll feel when you achieve it.</p>

        <form className="contact-us-form" onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            className="form-input"
            value={formData.email}
            onChange={handleChange}
          />

          <div className="form-input-group">
            <input
              type="text"
              name="firstName"
              placeholder="First name"
              required
              className="form-input"
              value={formData.firstName}
              onChange={handleChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last name"
              required
              className="form-input"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>

          <input
            type="text"
            name="description"
            placeholder="Description (optional)"
            className="form-input"
            value={formData.description}
            onChange={handleChange}
          />
          <input
            type="text"
            name="reason"
            placeholder="Reason of contact"
            className="form-input"
            required
            value={formData.reason}
            onChange={handleChange}
          />

          {/* <select
            name="subject"
            className="form-input"
            required
            value={formData.subject}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Subject
            </option>
            <option value="general">General Inquiry</option>
            <option value="support">Support</option>
            <option value="feedback">Feedback</option>
            <option value="other">Other</option>
          </select> */}

          <textarea
            name="message"
            placeholder="Message"
            className="form-input"
            required
            value={formData.message}
            onChange={handleChange}
          ></textarea>

          <button type="submit" className="submit-btn">
            Send request
          </button>
        </form>
        {showSuccess && <SuccessPopup message="Your request was submitted successfully!" onClose={() => setShowSuccess(false)} />}
        {showFailure && <FailurePopup message="There was an error submitting your request. Please try again." onClose={() => setShowFailure(false)} />}
      </div>
    </div>
  );
};

export default ContactUs;
