// BecomeAPartner.js
import React , { useState, useEffect , lazy} from 'react';
import './/BecomeAPartner.css';
import Hero from './Components/HeroSection';
import Info from './Components/InfoSection';
import How2 from './Components/HowTo';
import FAQs from './Components/FAQs';
import Contact from './Components/ContactUs';
import { Helmet } from 'react-helmet-async';
import { SpeedInsights } from "@vercel/speed-insights/react";
import LogoAnimation from '../Landing/LogoAnimation';

const BecomeAPartner = () => {
  const [showAnimation, setShowAnimation] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const [isHeroAssetsLoading , setIsHeroAssetsLoading ] = useState(true)
  const [isInfoAssetsLoading , setIsInfoAssetsLoading ] = useState(true)
  const [isHowToAssetsLoading , setIsHowToAssetsLoading ] = useState(true)
  const [isFAQsAssetsLoading , setIsFAQsAssetsLoading ] = useState(true)
  const [isContactUsAssetsLoading , setIsContactUsAssetsLoading ] = useState(true)

  useEffect(() => {
    if (!isHeroAssetsLoading && !isInfoAssetsLoading && !isHowToAssetsLoading && !isFAQsAssetsLoading && !isContactUsAssetsLoading) {
      setIsVisible(false);
      setShowAnimation(false);
    }
  }
  , [isHeroAssetsLoading, isInfoAssetsLoading, isHowToAssetsLoading, isFAQsAssetsLoading, isContactUsAssetsLoading]);
  
  const renderContent = () => (
    <div className="home-container">
      <div className="content">
        <Hero onAssetsLoaded={() => setIsHeroAssetsLoading(false)}/>
        <Info onAssetsLoaded={() => setIsInfoAssetsLoading(false)}/>
        <How2 onAssetsLoaded={() => setIsHowToAssetsLoading(false)}/>
        <FAQs onAssetsLoaded={() => setIsFAQsAssetsLoading(false)}/>
        <Contact onAssetsLoaded={() => setIsContactUsAssetsLoading(false)}/>
      </div>
    </div>
  );


  return (
    <div className="partner-page">
      <SpeedInsights />
      <Helmet>
        <title>Become a Partner - Trego Tech</title>
        <meta name="description" content="Join Trego Tech as a partner and grow your business with our innovative solutions. Super charge your business by filling gaps with our cutting-edge tech soloutions, starting today" />
        <meta name="keywords" content="Trego Tech, partner, innovative solutions, business growth" />
        <meta property="og:title" content="Become a Partner - Trego Tech" />
        <meta property="og:description" content="oin Trego Tech as a partner and grow your business with our innovative solutions. Super charge your business by filling gaps with our cutting-edge tech soloutions, starting today" />
        <meta property="og:url" content="https://www.tregotech.com/partner" />
        <meta property="og:type" content="website" />
      </Helmet>
      {showAnimation && (
        <LogoAnimation isVisible={isVisible} onAnimationComplete={() => setShowAnimation(false)} />
      )}
      { renderContent() }

    </div>
  );
};

export default BecomeAPartner;
