import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Logo from '../../assets/HeaderLogo.webp';

const LogoGlitchAnimation = ({ isVisible , onAnimationComplete }) => {



  const glitchKeyframes = {
    '0%': { clipPath: 'inset(50% 0 50% 0)' },
    '5.5555555556%': { clipPath: 'inset(0 0 50% 0)' },
    '11.1111111111%': { clipPath: 'inset(50% 0 0 0)' },
    '16.6666666667%': { clipPath: 'inset(0 50% 0 50%)' },
    '22.2222222222%': { clipPath: 'inset(0 0 0 50%)' },
    '27.7777777778%': { clipPath: 'inset(50% 50% 50% 50%)' },
    '33.3333333333%': { clipPath: 'inset(0 0 50% 0)' },
    '38.8888888889%': { clipPath: 'inset(50% 0 0 0)' },
    '44.4444444444%': { clipPath: 'inset(0 50% 0 50%)' },
    '50%': { clipPath: 'inset(50% 0 50% 0)' },
    '100%': { clipPath: 'inset(50% 0 50% 0)' },
  };

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: isVisible ? 1 : 0 }}
      transition={{ duration: 0.5 }}
      onAnimationComplete={() => !isVisible && onAnimationComplete?.()}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000',
        zIndex: 9999,
      }}
    >
      <motion.div
        style={{
          position: 'relative',
          width: '200px',
          height: '200px',
        }}
      >
        <motion.img
          src={Logo}
          alt="Company Logo"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          animate={{
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 2,
            times: [0, 0.5, 1],
            repeat: 1,
          }}
        />
        <motion.div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url(${Logo}) no-repeat center center`,
            backgroundSize: 'contain',
            filter: 'hue-rotate(90deg)',
          }}
          animate={{
            clipPath: glitchKeyframes,
          }}
          transition={{
            duration: 2,
            times: Object.keys(glitchKeyframes).map(Number),
            repeat: Infinity,
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default LogoGlitchAnimation;
