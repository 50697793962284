import React , { useState , useEffect}from 'react';
import { motion } from 'framer-motion';
import './AboutUs.css';
import HeaderBackground from '../../assets/LandingHeaderBackground.webp';
import JoinUsImg from '../../assets/JoinsUsLandingImg.webp';
import BlueLogo from '../../assets/bluewhite2.webp';
import MeetOurTeamBackground from '../../assets/meetourteambackground.webp';
import InteractiveLogo from './components/InteractiveLogo.js';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { SpeedInsights } from "@vercel/speed-insights/react";
import LogoAnimation from '../Landing/LogoAnimation';

const AboutUs = () => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  const navigate = useNavigate(); // Initialize the hook

  const handleClickContact = () => {
    navigate('/contact'); // Navigate to the ContactUs page
  }

  const handleClickPartner = () => {
    navigate('/partner'); // Navigate to the ContactUs page
  }

  const [showAnimation, setShowAnimation] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [isAssetsLoading , setIsAssetsLoading ] = useState(true)
  
  useEffect(() => {
    const LoadAssets = async () => {
      try {
        const blueLogoPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = BlueLogo;
          img.onload = () => resolve();
          img.onerror = () => reject();
        });
        const joinUsImgPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = JoinUsImg;
          img.onload = () => resolve();
          img.onerror = () => reject();
        });

        const headerBackgroundPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.src = HeaderBackground;
          img.onload = () => resolve();
          img.onerror = () => reject();
        });

        await Promise.all([blueLogoPromise, joinUsImgPromise, headerBackgroundPromise]);
        setIsAssetsLoading(false);
      }
      catch (error) {
        console.error("Error loading assets:", error);
      }
    }
    LoadAssets();
  }
  , []);

  useEffect(() => {
    if (!isAssetsLoading) {
      setIsVisible(false);
      setShowAnimation(false);
    }
  }
  , [isAssetsLoading]);


  return (

    <>
        {showAnimation && (
      <LogoAnimation isVisible={isVisible} onAnimationComplete={() => setShowAnimation(false)} />
    )
    }
      <div className="about-us">
      <SpeedInsights />
        <Helmet>
          <title>About Us - Trego Tech</title>
          <meta name="description" content="Learn more about Trego Tech, our vision, and our mission. We are a leading technology partner building innovative digital solutions across industries. We aim to transform the travel industry by providing a user-first experience and optimizing mobility," />
          <meta name="keywords" content="Trego Tech, about us, vision, mission, technology partner, digital solutions" />
          <meta property="og:title" content="About Us - Trego Tech" />
          <meta property="og:description" content="Learn more about Trego Tech, our vision, and our mission. We are a leading technology partner building innovative digital solutions across industries. We aim to transform the travel industry by providing a user-first experience and optimizing mobility," />
          <meta property="og:url" content="https://www.tregotech.com/about" />
          <meta property="og:type" content="website" />
        </Helmet>
        
        <motion.section 
          className="hero-section" 
          style={{ backgroundImage: `url(${HeaderBackground})` }}
          initial="hidden"
          whileInView="visible"
          variants={fadeInUp}
          viewport={{ amount: 0.2 }}  // Keeps animation active every time it enters view
        >
          <motion.div className="hero-content">
            <h1>About Us</h1>
            <p>Home / About Us</p>
          </motion.div>
        </motion.section>

        <motion.section 
          className="vision-mission"
          initial="hidden"
          whileInView="visible"
          variants={fadeInUp}
          viewport={{ amount: 0.2 }}  // Keeps animation active every time it enters view
        >
          <div className="vision-mission-content">
            <motion.div className="vision" variants={fadeInUp}>
              <div className="title">
                <h2>Our Vision</h2>
              </div>
              <div className="text">
                <p>
                To be a leading technology partner, build innovative digital solutions across industries, and enhance how businesses and users interact with technology.
                </p>
              </div>
            </motion.div>

            <motion.div className="mission" variants={fadeInUp}>
              <div className="title">
                <h2>Our Mission</h2>
              </div>
              <div className="text">
                <p>
                Our mission is to leverage the power of technology to create transformative software that meets today's needs while anticipating tomorrow's challenges. Whether it’s through travel, or new and emerging industries, we aim to build adaptable, future-proof solutions.
                </p>
              </div>
              {/* <div className="subtext">
                <p>Our expert team is dedicated to delivering top-notch, tailor-made applications that meet your unique needs.</p>
              </div> */}
              <div className='vision-mission-btn'>
              <button  onClick={handleClickContact}> Contact us </button>
              </div>
            </motion.div>
          </div>
          <motion.div className="image-box" variants={fadeInUp}>
            {/* <InteractiveLogo src={BlueLogo} alt="Vision" /> */}
            <img src={BlueLogo} alt="Vision" />
          </motion.div>
        </motion.section>

        <motion.section 
          className="cta1"
          initial="hidden"
          whileInView="visible"
          variants={fadeInUp}
          viewport={{ amount: 0.2 }}  // Keeps animation active every time it enters view
        >
          <motion.div className="cta1-content" variants={fadeInUp}>
            <p className="cta1-title">Join Us Now</p>
            <h2>WE ARE ALWAYS READY TO CUSTOM SOLUTIONS FOR SEAMLESS RESULTS</h2>
            <button className="button" onClick={handleClickPartner}>Become a partner!</button>

          </motion.div>
          <motion.img src={JoinUsImg} alt="Collaborative team" variants={fadeInUp} />
        </motion.section>
{/* 
        <motion.section 
          className="meet-our-team" 
          style={{ backgroundImage: `url(${MeetOurTeamBackground})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat'}}
          initial="hidden"
          whileInView="visible"
          variants={fadeInUp}
          viewport={{ amount: 0.2 }}  // Keeps animation active every time it enters view
        >
          <div className="team-header">
            <h2>Meet Our Team</h2>
            <p>Meet the Minds Shaping Your Future</p>
          </div>
          <div className="team-grid">
            {['Mazen Kamal', 'Amr Kotb'].map((name, index) => (
              <motion.div 
                className="team-member" 
                key={index} 
                variants={fadeInUp}
              >
                {/* <motion.img 
                  className="team-photo" 
                  src={teamMember} 
                  alt={`${name}'s photo`}
                  variants={fadeInUp}
                /> 
                <h3>{name}</h3>
                <p>{index === 0 || index === 2 ? 'CEO & CO-FOUNDER' : 'CTO & CO-FOUNDER'}</p>

              </motion.div>
            ))}
          </div>

        </motion.section>
         */}
      </div>
    </>
  );
}

export default AboutUs;
